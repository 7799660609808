<template>
  <div :class="['card', card.position]" :style="{ backgroundColor: bg_color }">
    <div class="imageContainer">
      <template v-if="!!card.media">
        <Image v-if="card.media.type == 'img'" :img="card.media.media" />
        <video v-else autoplay loop muted :poster="poster" playsinline preload>
          <source :src="card.media.media" />
        </video>
      </template>
    </div>
    <div class="text">
      <h2>{{ card.title }}</h2>
      <ul class="desktop">
        <li v-for="line in card.text.split(/\r?\n/)">
          {{ line }}
        </li>
      </ul>
    </div>
    <ul class="mobile">
      <li v-for="line in card.text.split(/\r?\n/)">
        {{ line }}
      </li>
    </ul>
  </div>
</template>
<script setup>
import { computed } from "vue";
var props = defineProps({
  card: Object,
  bg_color: String,
});

const poster = computed(() => {
  if (props.card.poster) {
    return props.card.poster.sizes[2].src || "";
  } else return "";
});
</script>
<style lang="scss" scoped>
.card {
  position: relative;
  z-index: 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  aspect-ratio: 2 / 1;

  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 14px;

    li {
      margin-left: 29px;
      position: relative;
      font-weight: 500;
      line-height: 1.3;

      @media screen and (min-width: 800px) {
        font-size: 2rem;
      }

      &::before {
        content: "";
        display: block;
        background-color: $volt;
        height: 18px;
        width: 18px;
        position: absolute;
        left: -29px;
        top: 4px;
        border-radius: 50%;
      }
    }
  }

  .mobile {
    display: none;
  }

  .text {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    padding: 40px 40px 40px 0px;
    box-sizing: border-box;
    gap: 30px;

    h2 {
      margin: 0;
      font-size: 30px;
      line-height: 1.2;
    }
  }

  .imageContainer {
    min-width: 0;
    background-color: inherit;

    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: contain;
      background-color: inherit;
    }
  }

  &.l {
    .text {
      order: -1;
      padding: 40px 0px 40px 40px;
    }
  }
}

@media screen and (max-width: 1100px) {
  .card {
    ul {
      gap: 10px;
    }
    ul li {
      font-size: 1.6rem;
      line-height: 1.5;
      &::before {
        height: 18px;
        width: 18px;
        left: -29px;
        top: 3px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .card {
    // grid-template-columns: 1fr !important;
    display: flex;
    flex-direction: column;
    aspect-ratio: unset;
    // height: 130vw;

    .imageContainer {
      flex-shrink: 3;
      flex-grow: 3;
      min-height: 0;
      margin: -10px 0px 8px 0px;
    }

    .text {
      order: -1 !important;
      padding: 20px 20px 0px 20px !important;
      z-index: 10;

      h2 {
        font-size: 20px;
        line-height: 1.2;
        text-align: center;
      }
    }

    .desktop {
      display: none;
    }

    .mobile {
      display: flex !important;
      padding: 0px 20px 20px 20px !important;
      z-index: 20;
    }
  }
}
</style>
