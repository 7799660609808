<template>
  <div class="vorteile navPadding">
    <TextPart
      :pretitle="vorteile_page.new_intro_pretitle"
      :title="vorteile_page.new_intro_title"
      :text="vorteile_page.new_intro_text"
    />
    <div class="functionsContainer">
      <div class="wLimiter">
        <StackingCards :cards="vorteile_page.functions" :backgroundVideo="vorteile_page.carousel_background_media">
          <template v-slot:card="props">
            <CardContent :card="props.card" :bg_color="vorteile_page.card_bg" />
          </template>
        </StackingCards>
      </div>
      <div class="wLimiter">
        <DevicePresentation :content="vorteile_page" />
      </div>
    </div>
    <NewsletterSignup />
  </div>
</template>

<script>
import AnimationContainer from "../components/general/AnimationContainer.vue";
import CardContent from "../components/vorteile/CardContent.vue";
import StackingCards from "../components/vorteile/StackingCards.vue";
import DevicePresentation from "../components/vorteile/DevicePresentation.vue";
import { mapState } from "vuex";
export default {
  components: {
    AnimationContainer,
    StackingCards,
    CardContent,
    DevicePresentation,
  },
  mounted() {},
  computed: {
    ...mapState(["vorteile"]),
    vorteile_page() {
      return this.vorteile[this.currLocale];
    },
  }
};
</script>

<style lang="scss" scoped>
.vorteile {
  @include defaultFontSize;
}

.functionsContainer {
  padding: 0px 20px;

  .wLimiter {
    max-width: $max-width;
    margin: 0px auto;
  }
}
</style>
