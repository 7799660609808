<template>
  <div :style="cardStyle" class="stackingCard">
    <div class="background">
      <video autoplay loop muted playsinline preload="auto">
        <source :src="backgroundVideo" />
      </video>
    </div>
    <slot />
    <div class="overlay" />
  </div>
</template>

<style lang="scss">
.stackingCard {
  position: sticky;
  top: 100px;
  background-color: $darkGrey;
  border-radius: 20px;
  overflow: hidden;
  transform-origin: top;
  // padding: 46px;
  color: white;
  // box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.2);

  .background {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 0;
    }
  }
  .overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: var(--overlay);
    z-index: 4;
  }
}

@media screen and (max-width: 800px) {
  .stackingCard {
    position: relative;
    top: 0;
    transform: none !important;
    opacity: 1 !important;
    .overlay {
      opacity: 0 !important;
    }
  }
}
</style>

<script setup>
import { computed } from "vue";

const props = defineProps({
  n: {
    type: Number,
  },
  h: {
    type: Number,
  },
  s: {
    type: Number,
  },
  o: {
    type: Number,
  },
  shouldStyle: {
    type: Boolean,
  },
  backgroundVideo: {
    type: String,
  },
});
const progress = computed(() => {
  return Math.max(0, props.s - (props.o + props.h * props.n)) / props.h;
});

const staggeredProgress = computed(() => {
  return Math.floor(progress.value) + Math.min(1, (progress.value % 1) * 3);
});

const finalOpacity = computed(() => {
  return 1 - Math.max(Math.min((progress.value - 3) * 3, 1), 0);
});

const cardStyle = computed(() => {
  var s = 1 - 0.05 * staggeredProgress.value;
  var y = 15 - staggeredProgress.value * 15;
  var overlayOpacity = 0.25 * staggeredProgress.value;
  if (props.shouldStyle) {
    return {
      transform:
        "scale(" + s + "," + s + ") translateY(" + y + "px) translateZ(0px)",
      zIndex: props.n,
      // top: 70 + i * 15 + "px",
      "--overlay": overlayOpacity,
      opacity: finalOpacity.value,
    };
  } else return { zIndex: props.n, transform: "translateY(15px)" };
});
</script>
