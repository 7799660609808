<template>
  <div class="stackingContainer" ref="stackingcontainer">
    <StackingCard
      v-for="(card, i) in cards"
      :n="i"
      :h="height"
      :o="offset"
      :s="scroll"
      :shouldStyle="i != cards.length - 1"
      :backgroundVideo="backgroundVideo"
      :key="card.title"
    >
      <slot name="card" :card="card" />
    </StackingCard>
  </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref } from "vue";

import StackingCard from "./StackingCard.vue";

const props = defineProps({
  cards: {
    type: Array,
  },
  backgroundVideo: {
    type: String,
  },
});

let scroll = ref(0);
let height = ref(0);
let offset = ref(0);

let anim = ref(null);

const stackingcontainer = ref(null);

var doc = document.documentElement;

function updateScroll() {
  scroll.value = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
  anim.value = requestAnimationFrame(updateScroll);
}

function getSizes() {
  var [lastcard] = Array.from(
    stackingcontainer.value.querySelectorAll(".stackingCard")
  ).slice(-1);
  height.value = lastcard.getBoundingClientRect().height + 20;
  offset.value =
    stackingcontainer.value.getBoundingClientRect().y +
    (window.pageYOffset || doc.scrollTop) -
    (doc.clientTop || 0) -
    150;
}

onMounted(() => {
  anim.value = requestAnimationFrame(updateScroll);
  window.addEventListener("resize", getSizes);
  getSizes();
});

onBeforeUnmount(() => {
  cancelAnimationFrame(anim.value);
  window.removeEventListener("resize", getSizes);
});
</script>

<style lang="scss">
.stackingContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  --overlay: 0;
}
</style>
