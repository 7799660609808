<template>
  <a>
    <span class="textCenterer"> <slot /> </span>
  </a>
</template>

<style scoped lang="scss">
a {
  width: 127px;
  height: 127px;
  border-radius: 50%;
  position: absolute;
  background-color: $volt;
  box-shadow: 0px 3px 6px #00000029;
  text-align: center;
  z-index: 60;

  .textCenterer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;

    & > span {
      display: block;
      font-weight: 400;
    }
  }
}

@media screen and (max-width: 600px) {
  a {
  width: 97px;
  height: 97px;
 

  .textCenterer {
   
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;

  }
}
}
</style>
