<template>
  <div :class="['inner', slide.position]">
    <div class="imageContainer">
      <Image v-if="slide.media.type == 'img'" :img="slide.media.media" />
      <video
        v-else
        autoplay
        loop
        muted
        :poster="poster"
        playsinline
        preload
        ref="video"
      >
        <source :src="slide.media.media" />
      </video>
    </div>
    <div class="text">
      <h2>{{ slide.title }}</h2>
    </div>
  </div>
</template>
<script setup>
import { computed, watch, ref } from "vue";
var props = defineProps({
  slide: Object,
  visible: Boolean,
});

const video = ref(null);

const poster = computed(() => {
  if (props.slide.poster) {
    return props.slide.poster.sizes[2].src || "";
  } else return "";
});

const visible = computed(() => props.visible.value);

watch(visible, (to, from) => {
  if (to) {
    video.value.play();
  }
  if (!to) {
    video.value.pause();
    video.value.currentTime = 0;
  }
});
</script>

<style lang="scss" scoped>
.inner {
  position: relative;
  overflow: hidden;
  display: grid;
  flex-grow: 10;
  min-height: 0;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  color: white;

  .text {
    text-align: right;
    padding-top: 40px;

    h2 {
      margin: 0px;
    }
  }

  &.l .text {
    order: -1;
    text-align: left;
  }

  &.c {
    grid-template-columns: 1fr;
    gap: 40px;

    .text {
      text-align: center;
      margin: 0px;
      padding: 40px 0px 0px 0px;
      order: -1;

      h2 {
        max-width: 1000px;
        margin: 0px auto;
      }
    }

    .imageContainer {
      min-height: 0;
      padding-bottom: 20px;
    }
  }

  .imageContainer {
    // aspect-ratio: 1 / 1;
    width: 100%;
    // height: 100%;

    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: contain;
      pointer-events: none;
    }
  }
}

@media screen and (max-width: $breakpoint) {
  .inner {
    grid-template-columns: 1fr !important;
    gap: 40px;
    display: flex;
    flex-direction: column;
    h2 {
      text-align: center;
      padding: 0 var(--padding);
      box-sizing: border-box;
    }

    .imageContainer {
      min-height: 0;
      padding-bottom: 0 !important;
      aspect-ratio: 1/1;
      flex-shrink: 0;
    }

    .text {
      order: -1 !important;
      padding-top: 0px !important;
      flex-grow: 1;
      flex-shrink: 1;
      min-height: 0;
      z-index: 20;
    }
  }
}

@media screen and (max-width: 600px) {
  .inner h2 {
    @include bigFontSize;
    line-height: 1.2;
  }
}
</style>
